<template>
  <DialogFormWrapper>
    <template #form>
      <ClientForm
        class="pt-8"
        ref="newClientForm"
        :editing-client="{phoneNumber: prospect.phoneNumber}"
        @submitClientForm="submitClientForm"
      />
    </template>
    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="form-action"
        :loading="isProcessing"
        @click="$refs.newClientForm.submitClientForm()"
      >
        Dodaj klienta
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import ClientForm from './Partials/ClientForm'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    ClientForm,
    DialogFormWrapper
  },
  computed: {
    ...mapState({
      isProcessing: state => state.clients.isProcessing,
      prospect: state => state.prospect.entity
    })
  },
  methods: {
    ...mapActions({
      addNewClient: 'clients/addNewItem',
      getMiddlemen: 'middlemen/getItems',
      closeDialog: 'layout/closeDialog'
    }),
    submitClientForm (params) {
      this.addNewClient({ params: { ...params, prospectId: this.prospect.id } })
        .then((client) => {
          if (client.isMiddleman) this.getMiddlemen()
          this.$router.replace({ name: 'singleClient', params: { id: client.id } })
          this.closeDialog()
        })
    }
  }
}
</script>
